<template src="./CreateCampaignStepOne.html" lang=""></template>
<style lang="scss">
@import "./CreateCampaignStepOne.scss";
</style>

<script>
import { Input } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import { RadioButton } from "@progress/kendo-vue-inputs";
import Breadcrumb from "@/components/common/AppBreadCrumbs/BreadCrumbs.vue";
import { TabStrip, TabStripTab } from "@progress/kendo-vue-layout";
import AppSnackBarVue from "@/components/common/AppSnackBar/AppSnackBar.vue";
import { RadioGroup } from "@progress/kendo-vue-inputs";
import { verifyCheckCampaignService } from "@/services/clubDetails/emailCampaign.service";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";

export default {
  name: "CreateCampaignStepOne",
  components: {
    "k-input": Input,
    kbutton: Button,
    Breadcrumb,
    radiobutton: RadioButton,
    tabstrip: TabStrip,
    tabstripTab: TabStripTab,
    AppSnackBar: AppSnackBarVue,
    radiogroup: RadioGroup,
    AppLoader,
  },
  data() {
    return {
      moduleCode: "emailCampaign",
      itemCode: "allCampaign",
      creationTypeSerial: 1,
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      isDuplicateName: false,
      dataItems: [
        {
          label: "Email",
          value: 1,
        },
        {
          label: "SMS",
          value: 2,
        },
      ],
      distributionChannel: 1,
      campaignName: "",
      isLoading: false,
    };
  },

  mounted() {
    if (!this.hasCreateAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to this module.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
  },

  methods: {
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    handleChange(e) {
      this.distributionChannel = e.value;
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
    dataValidation() {
      if (!this.campaignName) {
        this.showNotification("Campaign name is required.", "warning");
        return false;
      }
      if (this.campaignName.length > 100) {
        this.showNotification(
          "Campaign name should not exceed 100 characters.",
          "warning"
        );
        return false;
      }
      if (this.campaignName.trim().length === 0) {
        this.showNotification("Campaign name should not be empty.", "warning");
        return false;
      }

      return true;
    },
    campaignTypeClickHandler(type) {
      this.creationTypeSerial = type;

      if (type === 1) {
        this.$refs.regularEmail.classList.add("active");
        this.$refs.advancedEmail.classList.remove("active");
      }
      if (type === 2) {
        this.$refs.advancedEmail.classList.add("active");
        this.$refs.regularEmail.classList.remove("active");
      }
    },
    doNavigateToCampaignHomepage() {
      this.$router.push({
        name: "AllCampaign",
      });
    },
    nameVerifyCheckCampaignService() {
      this.isLoading = true;
      var payload = {
        clubId: this.$route?.params?.id,
        campaignName: this.campaignName,
        campaignGroup: 1,
      };
      verifyCheckCampaignService(payload)
        .then((response) => {
          this.isLoading = false;
          if (!response.data.data) {
            this.$router.push({
              name: "CreateCampaignStepTwo",
              query: {
                type: 2,
                campaignType: this.creationTypeSerial,
                campaignName: this.campaignName,
                distributionChannel: this.distributionChannel,
              },
            });
          } else {
            this.showNotification("Campaign name already exists.", "warning");
            return;
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.showNotification("Something went wrong.", "error");
        });
      return this.isDuplicateName;
    },
    handleCreateEvent() {
      if (this.dataValidation()) {
        this.nameVerifyCheckCampaignService();
      }
    },
  },
};
</script>
<style lang=""></style>
